<template>
  <div class="grid grid-cols-2 sm:grid-cols-3">
    <div>
      <div class="mt-4">
        <div class="flex">
          <label class="font-semibold">{{$t('Created On')}}</label>
          <vx-tooltip v-if="taskListInfoData.status === 'completed' || taskListInfoData.status === 'failed'" :text="`Closed On: ${taskListInfoData.modified ? new Date(taskListInfoData.modified).toLocaleDateString('fr-CA') + '  ' + new Date(taskListInfoData.modified).toLocaleTimeString('en-GB') : ''}`">
            <feather-icon class="ml-2 cursor-pointer" icon="AlertCircleIcon" svgClasses="w-4 h-4 text-grey"></feather-icon>
          </vx-tooltip>
        </div>
        <h5>{{taskListInfoData.created ? new Date(taskListInfoData.created).toLocaleDateString('fr-CA') + '  ' + new Date(taskListInfoData.modified).toLocaleTimeString('en-GB') : ''}}</h5>
      </div>
      <div class="mt-4">
        <label class="font-semibold">{{$t('Created By')}}</label>
        <h5>{{taskListInfoData.creator ? `${taskListInfoData.creator.first_name} ${taskListInfoData.creator.last_name}` : ''}}</h5>
      </div>
      <div class="mt-4">
        <label class="font-semibold">{{$t('Agent')}}</label>
        <h5>{{taskListInfoData.customer_agent ? taskListInfoData.customer_agent.first_name + ' ' + taskListInfoData.customer_agent.last_name + ' (Last Mile Agent)' : taskListInfoData.merchant_agent.first_name + ' ' + taskListInfoData.merchant_agent.last_name + ' (First Mile Agent)'}}</h5>
      </div>
      <div class="mt-4">
        <vs-chip class="product-order-status" :color="getOrderStatusColor(taskListInfoData)">{{ taskListInfoData.status }}</vs-chip>
      </div>
    </div>
    <div>
      <template v-if="taskListInfoData.customer_agent">
        <div class="mt-4">
          <label class="font-semibold">{{$t('Payment Collections')}}</label>
          <h5>{{Number(dropOffsCash).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} {{$t('EGP')}}</h5>
        </div>
        <div class="mt-4">
          <label class="font-semibold flex">{{$t('Cash In-hand')}}<span class="material-symbols-outlined text-2xl ml-2">payments</span></label>
          <h5>{{Number(cashCollected).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} {{$t('EGP')}}</h5>
        </div>
        <div class="mt-4">
          <label class="font-semibold flex">{{$t('e-Payments')}}<span class="material-symbols-outlined text-2xl ml-2">credit_card</span> </label>
          <h5>{{Number(cridetCardCollected).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} {{$t('EGP')}}</h5>
        </div>
      </template>
    </div>
    <div v-if="taskListInfoData.customer_agent">
      <template v-if="custodyFlag && returnOrderAmount > 0"> 
        <div class="mt-4">
          <div class="flex">
            <vs-input name="Custody" v-validate="`required|numeric|max_value:${returnOrderAmount}|min_value:0`" @keyup.enter.native="updateTaskList('custody_from_khazna', Number(taskListInfoData.custody_from_khazna))" class="w-1/2 mr-2" v-if="custodyFlag" v-model="taskListInfoData.custody_from_khazna"/>
            <vs-button icon-pack="feather" icon="icon-check" color="success" @click="updateTaskList('custody_from_khazna', Number(taskListInfoData.custody_from_khazna))"></vs-button>   
            <vs-button icon-pack="feather" icon="icon-x" class="ml-1" color="danger" @click="custodyFlag = false"></vs-button>
          </div>
          <p class="text-danger text-sm w-full" v-show="errors.has('Custody')">{{ errors.first('Custody') }}</p> 
        </div>
      </template>
      <template v-else>
        <div class="mt-4">
          <label class="font-semibold">{{$t('Custody')}}</label>
          <h5 v-if="returnOrderAmount > 0 && $store.state.AppActiveUser.userRole !== 'finance'" :class="taskListInfoData.custody_status === 'received_by_agent' || taskListInfoData.status === 'completed' || taskListInfoData.status === 'failed' ? 'received' : taskListInfoData.custody_status === 'added_by_team_leader' ? 'link cursor-pointer' : ''" @click="changeCustodyFlag()">{{taskListInfoData.custody_from_khazna}} {{$t('EGP')}}</h5>
          <h5 v-else>{{taskListInfoData.custody_from_khazna}} {{$t('EGP')}}</h5>
        </div>
      </template>
      <div class="mt-4">
        <label class="font-semibold">{{$t('Cash deposited')}}</label>
        <h5>{{Number(taskListInfoData.cash_deposited).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} {{$t('EGP')}}</h5>
      </div>
      <div class="mt-4">
        <label class="font-semibold">{{$t('Shortfall')}}</label>
        <h5 :class="taskListInfoData.cash_deposited - cashCollected < 0 ? 'text-red' : ''">{{Number(taskListInfoData.cash_deposited - cashCollected).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} {{$t('EGP')}}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'

export default {
  props: ['taskSheetOrders', 'taskListInfoData', 'cashCollected', 'dropOffsCash', 'cridetCardCollected', 'returnOrderAmount'],
  data () {
    return {
      custodyFlag: false,
      oldCustody: 0
    }
  },
  watch: {
    custodyFlag (val) {
      val ? this.oldCustody = this.taskListInfoData.custody_from_khazna : this.taskListInfoData.custody_from_khazna = this.oldCustody
    }
  },
  methods: {
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order) ? this.getStatusLabel(order) : '')
    },
    changeCustodyFlag () {
      if (this.taskListInfoData.custody_status !== 'received_by_agent') {
        this.custodyFlag = true
      }
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    updateTaskList (field, value) {
      const obj = {}
      obj[field] = value
      this.$validator.validateAll().then(result => {
        if (result) {
          sendRequest(false, false, this, `api/v1/task-sheets/${this.$route.params.taskListID}/`, 'patch', obj, true,
            () => {
              this.$vs.notify({
                color: 'success',
                title: i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['Task List'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.custodyFlag = false
              this.$emit('loadShipments')
            }
          )
        }
      })
    }
  }
}
</script>